import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { PageFeatureConfigSymbol, IPageWillMountHandler, IPropsStore, Props } from '@wix/thunderbolt-symbols'
import { name, ReactLoaderForOOISymbol } from './symbols'
import type { OOIPageConfig } from './types'
import { OOIComponentLoader } from './types'
import _ from 'lodash'

export const ooiLoadComponentsPageWillMount = withDependencies(
	[ReactLoaderForOOISymbol, named(PageFeatureConfigSymbol, name), Props],
	(
		ooiComponentsLoader: OOIComponentLoader,
		{ ooiComponents }: OOIPageConfig,
		propsStore: IPropsStore
	): IPageWillMountHandler => {
		return {
			async pageWillMount() {
				await Promise.all(
					_.map(ooiComponents, async ({ widgetId }, compId) => {
						const ReactComponent = await ooiComponentsLoader.getComponent(widgetId)
						propsStore.update({
							[compId]: {
								ReactComponent,
							},
						})
					})
				)
			},
		}
	}
)
