import type { ComponentType, MouseEventHandler } from 'react'
import type { Reporter } from '../reporting'

export type Props = {
	id: string
	ReactComponent?: ComponentType<any>
	host: any
	sentryDsn?: string
	reporter: Reporter
	onMouseLeave: MouseEventHandler
	onMouseEnter: MouseEventHandler
	__VIEWER_INTERNAL?: { failedInSsr: boolean }
}

export const getStyle = ({ host }: Props) => (host && host.isResponsive ? {} : { height: 'auto' })
