import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	Props,
	IPropsStore,
	PageFeatureConfigSymbol,
	SiteFeatureConfigSymbol,
	ExperimentsSymbol,
	Experiments,
} from '@wix/thunderbolt-symbols'
import { name, ReactLoaderForOOISymbol } from './symbols'
import { OOIComponentLoader, OOIPageConfig, OOISiteConfig } from './types'
import { createHostProps } from './hostProps'
import _ from 'lodash'
import { ISiteScrollBlocker, SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'
import { IOoiTpaSharedConfig, OoiTpaSharedConfigSymbol } from 'feature-ooi-tpa-shared-config'

export const ooiComponentWillMount = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		ReactLoaderForOOISymbol,
		Props,
		named(SiteFeatureConfigSymbol, name),
		SiteScrollBlockerSymbol,
		OoiTpaSharedConfigSymbol,
		ExperimentsSymbol,
	],
	(
		{ ooiComponents, accessibilityEnabled }: OOIPageConfig,
		ooiComponentsLoader: OOIComponentLoader,
		propsStore: IPropsStore,
		{ viewMode, formFactor }: OOISiteConfig,
		siteScrollBlocker: ISiteScrollBlocker,
		{ getFontsConfig }: IOoiTpaSharedConfig,
		experiments: Experiments
	) => {
		return {
			componentTypes: ['tpaWidgetNative'],
			async componentWillMount({ id: compId }: { id: string }) {
				const { widgetId } = ooiComponents[compId]
				const compData = ooiComponents[compId]
				const ReactComponent = await ooiComponentsLoader.getComponent(widgetId)
				const hostProps = createHostProps({
					compData,
					pageId: 'c1dmp', // TODO: implement pageId
					accessibilityEnabled,
					formFactor,
					viewMode,
					siteScrollBlocker,
					registerToThrottledScroll: _.noop,
					fonts: getFontsConfig(),
					experiments,
				})
				propsStore.update({
					[compId]: {
						ReactComponent,
						host: {
							...hostProps,
							registerToComponentDidLayout: _.noop,
							unregisterFromComponentDidLayout: _.noop,
						},
					},
				})
			},
		}
	}
)
